<template>
	<div class="row">
		<div class="col-6"><b-form-input v-model="prefix" @change="loadImages" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Escriba el prefijo y dé enter para obtener imagenes"></b-form-input></div>

		<div class="col-2 text-center">
			<span v-if="images.length">{{ processeced.length }} de {{ images.length }} imagenes.</span>
		</div>
		<div class="col-1 text-right">
			<b-button v-if="imgSelected" v-on:click="passedBallot" variant="danger">Acta ilegible</b-button>
		</div>
		<div class="col-1 text-right">
			<b-button v-if="points.length > 0" v-on:click="returnPoint" variant="danger">Borrar último punto</b-button>
		</div>

		<div class="containerImg" v-on:click="capturePoint">
			<img @load="imageLoaded" style="position: absolute" v-if="imgSelected" v-show="!imageLading" :src="imageBase + imgSelected.Key" />
		</div>
	</div>
</template>

<script>
const AWS = require('aws-sdk')
const urlService = 'https://7sylmnrx7l.execute-api.us-east-1.amazonaws.com/v1/updateballotinfo'
import axios from 'axios'
export default {
	components: {},
	data() {
		return {
			imgSelected: null,
			images: [],
			processeced: [],
			points: [],
			index: 0,
			prefix: '',
			imageBase: 'https://actas-prep.s3.amazonaws.com/',
			loader: null,
			imageLading: true
		}
	},
	mounted() {
		AWS.config.update({
			apiVersion: '2006-03-01',
			apiVersion: '2006-03-01',
			accessKeyId: 'AKIASKE6GNGXMQJGEF4Y',
			secretAccessKey: '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',
			region: 'us-east-1'
		})
	},
	methods: {
		imageLoaded(evt) {
			console.log('on imageLoaded', evt)
			this.imageLading = false
		},
		setLoader(isShow) {
			console.log('isShow', isShow)
			if (isShow) {
				this.loader = document.createElement('div')
				this.loader.className = 'text-center'
				this.loader.style.paddingTop = '200px'
				this.loader.style.backgroundColor = 'grey'
				this.loader.style.position = 'absolute'
				this.loader.style.zIndex = '100'
				this.loader.style.width = '100%'
				this.loader.style.height = '100%'
				this.loader.style.opacity = '70%'
				this.loader.innerHTML = `<span role="status" class="spinner-grow" style="width: 6rem; height: 6rem;color:white"><span class="sr-only">Large Spinner</span></span><div style="color:white;font-size:100px">Cargando...</div>`
				document.body.prepend(this.loader)
			} else {
				this.loader.parentElement.removeChild(this.loader)
			}
		},
		loadImages() {
			this.images = []
			this.processeced = []
			this.imgSelected = null
			const params = {
				Bucket: 'actas-prep'
			}
			if (this.prefix) {
				params.Prefix = this.prefix
			} else {
				alert('Debe agregar un prefijo para poder mostrar las actas')
				return false
			}
			var s3 = new AWS.S3()
			s3.listObjects(params, (err, data) => {
				if (err) {
					alert('Ocurrio un error al obtener las imagenes')
					console.error(err)
					return
				}
				if (data.Contents.length == 0) {
					alert('No se encontrarón resultados para el prefijo ' + this.prefix)
					return
				}
				this.images = data.Contents.filter((img) => {
					//img.Key = img.Key.replace('%20', '%2520')
					return img.Key.indexOf('%20') < 0
				})
				this.imageLading = true
				this.imgSelected = this.images[this.index]
			})
		},
		nextImage() {
			this.imageLading = true
			this.index++
			this.imgSelected = this.images[this.index]
			this.processeced.push(this.points)
			this.cleanPoints(this.points)
		},
		passedBallot() {
			this.callApi({
				img: this.imgSelected.Key,
				passed: true
			}).then(() => {
				this.nextImage()
			})
		},
		callApi(data) {
			console.log('callApi', data)
			this.setLoader(true)
			return axios({
				url: urlService,
				data,
				method: 'post',
				timeout: 30000,
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then((result) => {
					return result.data
				})
				.finally(() => {
					this.setLoader(false)
				})
		},
		orderPoints(points) {
			return new Promise((resolve, reject) => {
				console.log('orderPoints', points)
				points = JSON.parse(JSON.stringify(points))
				var img = new Image()
				const keyImage = this.imgSelected.Key
				img.src = this.imageBase + keyImage
				img.onload = function () {
					const w = this.width
					const h = this.height
					if (!w && !h) {
						reject({ code: -1 })
						return
					}
					const quarter = [
						{ x: 0, y: 0 },
						{ x: w, y: 0 },
						{ x: 0, y: h },
						{ x: w, y: h }
					]
					const pointsIndex = { 0: {}, 1: {}, 2: {}, 3: {} }
					for (let i in quarter) {
						let pointClosest = {
							d: 100000
						}
						for (let j in points) {
							const x1 = points[j].x
							const x2 = quarter[i].x
							const y1 = points[j].y
							const y2 = quarter[i].y
							const X = x1 - x2
							const Y = y1 - y2
							const d = Math.sqrt(Math.pow(X, 2) + Math.pow(Y, 2))
							if (d < pointClosest.d) {
								pointClosest = {
									d,
									point: points[j],
									index: j
								}
							}
						}
						const x = pointClosest.point.x
						const y = pointClosest.point.y
						pointsIndex[i] = { x: x / w, y: y / h }
						points.splice(pointClosest.index, 1)
					}
					resolve({
						code: 1,
						data: {
							points: pointsIndex,
							img: keyImage
						}
					})
				}
			})
		},
		cleanPoints(points) {
			console.log('cleanPoints', points)
			points.forEach((element) => {
				console.log('element', element)
				element.domElement.parentElement.removeChild(element.domElement)
				delete element.domElement
			})
			this.points = []
		},
		returnPoint() {
			const lastPoint = this.points.pop()
			lastPoint.domElement.parentElement.removeChild(lastPoint.domElement)
			delete lastPoint.domElement
			console.log('points', this.points)
			console.log('lastPoint', lastPoint)
		},
		drawPoint(x, y, parent, color) {
			color = color || 'red'
			const point = document.createElement('div')
			point.className = 'divPoint'
			point.id = new Date().getTime()
			const c = -10
			point.style.left = x + c + 'px'
			point.style.top = y + c + 'px'
			point.style.backgroundColor = color
			document.getElementsByClassName(parent)[0].appendChild(point)
			return point
		},
		capturePoint(event) {
			console.log('event', event)
			if (this.points.length == 8) {
				return
			}
			const x = Number(event.offsetX)
			const y = Number(event.offsetY)
			const type = this.points.length >= 4 ? 'table' : 'ballot'
			const color = type == 'ballot' ? 'red' : 'chartreuse'
			this.points.push({ x: x < 0 ? 0 : x, y: y < 0 ? 0 : y, domElement: this.drawPoint(x, y, 'containerImg', color), type })
			if (this.points.length == 8) {
				setTimeout(() => {
					var r = confirm('Presione Ok para pasar a la siguiente o cancel para empezar de nuevo')
					if (r == true) {
						const pointsBallot = this.points.filter((point) => {
							return point.type == 'ballot'
						})
						const pointsTable = this.points.filter((point) => {
							return point.type == 'table'
						})
						this.orderPoints(pointsBallot)
							.then((resultPoints) => {
								const ballotPoints = resultPoints.data.points
								this.orderPoints(pointsTable)
									.then((resultPoints) => {
										const dataset = {
											ballotPoints,
											tablePoints: resultPoints.data.points,
											img: resultPoints.data.img.replace('%2520', '%20')
										}
										this.callApi(dataset)
											.then((result) => {
												console.log('this.callApi', result)
												if (result.code < 0) {
													alert(result.message + '\n' + JSON.stringify(dataset))
													return
												}
												this.nextImage()
											})
											.catch((err) => {
												console.log('Error en la respuesta del servicio', err)
												alert('Error en la respuesta del servicio', err)
											})
									})
									.catch((err) => {
										console.log('Error', err)
										alert('Error al obtener los puntos internos de la imagen', err)
									})
							})
							.catch((err) => {
								console.log('Error', err)
								alert('Error al obtener los puntos del acta', err)
							})
					} else {
						this.cleanPoints(this.points)
					}
				}, 500)
			}
		}
	}
}
</script>

<style>
.containerImg {
	background-color: antiquewhite;
	margin: 0px;
	padding: 1px;
	width: 100%;
	position: relative;
}
.divPoint {
	z-index: 10;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	position: absolute;
}
</style>
